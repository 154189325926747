import 'whatwg-fetch';
const metadata = require('../metadata.json');

const env = process.env.REACT_APP_API_ENV || 'development';
const apiBaseUrl = metadata.environments[env].apiUrl.replace(/\/+$/, '');

export const getServiceCancellationData = (uuid, transactionType) => {
  const cancellationType = transactionType === 'return' ? 'return' : 'purchase';

  return window
    .fetch(
      `${apiBaseUrl}/register/cancel/${uuid}?cancellationType=${cancellationType === 'return' ? 'return' : 'purchase'}`,
      {
        method: 'GET'
      }
    )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error(response.error);
    });
};

export const cancelService = (uuid, serviceCancelRequest) => {
  return window.fetch(`${apiBaseUrl}/register/cancel/${uuid}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(serviceCancelRequest)
  });
};
