import React, { Fragment } from 'react';

const CancellationReasonSelector = ({
  question,
  answers,
  selectedCategory,
  selectCategory,
  setFreeTextReason,
  freeTextReason,
  selectSubReason,
  selectedSubReason
}) => {
  return (
    <Fragment>
      <h2 className="mb-25">{question}</h2>
      <div className="ml-25">
        {answers.map((answer, i) => {
          const isChecked = selectedCategory ? selectedCategory.id === answer.id : false;
          return (
            <div key={answer.id} className="mt-15">
              <input
                type="radio"
                value={answer.id}
                checked={isChecked}
                name="cancellation-category"
                id={`category-${answer.id}`}
                onChange={selectCategory}
                required
              />
              <label htmlFor={`category-${answer.id}`}>{answer.category}</label>
              {isChecked && !selectedCategory.isFreeText ? (
                <div className="ml-20 mb-5">
                  <div className="mb-15 mt-15">
                    Tack! Vi hade uppskattat om du vill förtydliga ditt svar ytterligare (frivilligt)
                  </div>
                  {answer.subReasons.map((reason, i, arr) => (
                    <div key={`${answer.id}-${reason.id}`} className="ml-20 mb-10">
                      <input
                        type="radio"
                        name={`${reason.id}-sub-reasons`}
                        value={reason.id}
                        checked={window.parseInt(selectedSubReason?.id, 10) === i}
                        id={`${answer.id}-${reason.id}`}
                        onChange={selectSubReason}
                      />
                      <label htmlFor={`${answer.id}-${reason.id}`}>{reason.reason}</label>
                      {reason.isFreeText && window.parseInt(selectedSubReason?.id, 10) === i ? (
                        <textarea
                          onChange={setFreeTextReason}
                          value={freeTextReason}
                          required
                          className="mt-20"
                          placeholder="Ange varför du vill lämna tjänsten."
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      {selectedCategory && selectedCategory.isFreeText ? (
        <textarea
          className="mt-20"
          onChange={setFreeTextReason}
          value={freeTextReason}
          required
          placeholder="Ange varför du vill lämna tjänsten."
        />
      ) : null}
    </Fragment>
  );
};

export default CancellationReasonSelector;
