import React from 'react';

const ErrorMessage = ({ error, message }) => {
  if (!error) return null;

  return (
    <div className="error-message mb-20">{message}</div>
  );
};

export default ErrorMessage;