import React, { useReducer, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { cancelService, getServiceCancellationData } from './api/apiClient';
import { initialState, reducer, actionTypes } from './reducers/appReducer';
import AddressConfirmation from './components/AddressConfirmation';
import Button from './components/Button';
import CancellationReasonSelector from './components/CancellationReasonSelector';
import Card from './components/Card';
import ContentLoader from './components/ContentLoader';
import ErrorMessage from './components/ErrorMessage';
import Header from './components/Header';
import LoadingIcon from './components/LoadingIcon';
import Visible from './components/Visible';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const memberUuid = params.get('patient');
    const transactionType = params.get('type') || 'return';

    dispatch({ type: actionTypes.setMemberUuid, memberUuid });
    dispatch({ type: actionTypes.setLoadingState, isLoading: true });
    dispatch({ type: actionTypes.setTransactionType, transactionType });

    getServiceCancellationData(memberUuid, transactionType)
      .then((json) => {
        dispatch({ type: actionTypes.setMemberData, response: json });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.setMemberDataError, error });
      });
  }, []);

  const submitForm = (e) => {
    e.preventDefault();

    const answer = {
      category: state.selectedCategory.category,
      reason: state.selectedSubReason ? state.selectedSubReason.reason : null,
      comment: state.selectedCategory?.isFreeText || state.selectedSubReason?.isFreeText ? state.freeTextReason : null
    };

    const serviceCancelRequest = {
      answer,
      cancellationType: state.transactionType === 'return' ? 'return' : 'purchase'
    };

    if (state.memberDataUpdated) {
      serviceCancelRequest.phoneNumber = state.memberData.phoneNumber;
    }

    dispatch({ type: actionTypes.submitForm });

    cancelService(state.memberUuid, serviceCancelRequest)
      .then((response) => {
        if (response.ok) {
          dispatch({ type: actionTypes.submitFormSuccess });
        } else {
          throw new Error(response.error);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.submitFormError,
          error: error?.status === 403 ? 'Tjänsten är redan avslutad.' : 'Något gick fel. Vänligen försök igen.'
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>Blodtrycksdoktorn – {state.transactionType === 'buy' ? 'Skicka faktura' : 'Returnera mätaren'} </title>
      </Helmet>
      <div className="page-container">
        <Header />
        <ErrorMessage error={state.submitError} message={state.submitError} />
        <Card>
          <form onSubmit={submitForm}>
            <Visible when={state.isSubmittingForm}>
              <div className="mb-30 mt-30 centered">
                <LoadingIcon />
              </div>
            </Visible>
            <Visible when={!state.isSubmittingForm}>
              <Visible when={state.formSubmitted}>
                <div>
                  <h1 className="mb-20">Tråkigt att du lämnar oss.</h1>
                  <h2>
                    {state.transactionType === 'buy'
                      ? 'Fakturan skickas till den angivna e-postadressen och bör finnas i din inkorg strax.'
                      : 'En QR-kod skickas till dig via e-post och sms. Ta med dig mätaren till ett Postnord-ombud och visa upp QR-koden för att returnera mätaren. '}
                  </h2>
                </div>
              </Visible>
              <Visible when={!state.formSubmitted}>
                <Fragment>
                  <section>
                    <h1>{state.transactionType === 'buy' ? 'Skicka faktura' : 'Returnera mätaren'}</h1>
                  </section>
                  <Visible when={state.memberDataError}>
                    <ErrorMessage error={state.memberDataError} message="Den här länken är inte giltig." />
                  </Visible>
                  <Visible when={!state.memberDataError}>
                    <section>
                      <h2>Vad tråkigt att du vill lämna tjänsten.</h2>

                      {state.transactionType === 'buy' ? (
                        <p>
                          En faktura skickas via e-post till <strong>{state.memberData?.email}</strong>, samt via sms
                          till det telefonnummer du anger nedan.
                          {state.memberData?.devicePrice
                            ? ` Blodtrycksmätaren kostar ${state.memberData.devicePrice}.`
                            : ''}
                        </p>
                      ) : (
                        <p>
                          En QR-kod från Postnord skickas via e-post till <strong>{state.memberData?.email}</strong>,
                          samt via sms till det telefonnummer du anger nedan. Ta med dig mätaren till ett Postnord-ombud
                          och visa upp QR-koden för att returnera mätaren.
                        </p>
                      )}
                    </section>
                    <ContentLoader isLoading={state.isLoading}>
                      <AddressConfirmation
                        memberData={state.memberData}
                        isEditing={state.isEditingAddress}
                        updateMemberProperty={(property, value) => {
                          dispatch({
                            type: actionTypes.updateMemberDataProperty,
                            property,
                            value
                          });
                        }}
                        setIsEditingAddress={() => dispatch({ type: actionTypes.setIsEditingAddress })}
                      />

                      <CancellationReasonSelector
                        question={state.question}
                        answers={state.answers}
                        freeTextReason={state.freeTextReason}
                        setFreeTextReason={(e) =>
                          dispatch({
                            type: actionTypes.setFreeTextReason,
                            freeTextReason: e.target.value
                          })
                        }
                        selectedCategory={state.selectedCategory}
                        selectCategory={(e) =>
                          dispatch({
                            type: actionTypes.selectAnswerCategory,
                            categoryId: e.target.value
                          })
                        }
                        selectedSubReason={state.selectedSubReason}
                        selectSubReason={(e) =>
                          dispatch({
                            type: actionTypes.selectSubReason,
                            subReasonsId: e.target.value
                          })
                        }
                      />
                      <div className="mt-35 text-center">
                        <Button type="submit">
                          {state.transactionType === 'buy' ? 'Skicka faktura' : 'Beställ retursedel'}
                        </Button>
                      </div>
                    </ContentLoader>
                  </Visible>
                </Fragment>
              </Visible>
            </Visible>
          </form>
        </Card>
      </div>
    </>
  );
}

export default App;
