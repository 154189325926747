import React from 'react';
import LoadingIcon from '../LoadingIcon';

const ContentLoader = ({ children, isLoading }) => {
  return (
    isLoading
      ? <div className="mb-30 mt-30 centered"><LoadingIcon /></div>
      : children
  );
};

export default ContentLoader;