import React from 'react';

const AddressConfirmation = ({ memberData, updateMemberProperty }) => {
  return (
    <section>
      <div className="mt-20">
        <h3 className="text-light fw-normal mb-10">Telefonnummer</h3>
        <input
          type="tel"
          pattern="[0-9-\+\s]+"
          value={memberData.phoneNumber || ''}
          onChange={(e) => updateMemberProperty('phoneNumber', e.target.value)}
        />
      </div>
    </section>
  );
};

export default AddressConfirmation;
