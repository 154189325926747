const answers = [
  {
    id: 0,
    category: 'Jag vill fortsätta hos annan vårdgivare',
    subReasons: [
      {
        id: 0,
        reason: 'Jag har en nära relation till min vårdcentral'
      },
      {
        id: 1,
        reason: 'Jag vill se/möta min vårdgivare fysiskt'
      },
      {
        id: 2,
        reason: 'Jag har andra sjukdomar som jag går till vårdcentralen för så blir enklare att få behandling där'
      },
      {
        id: 3,
        reason: 'Annat',
        isFreeText: true
      }
    ]
  },
  {
    id: 1,
    category: 'Jag har inte längre behov',
    subReasons: [
      {
        id: 0,
        reason: 'Mitt blodtryck är under kontroll'
      },
      {
        id: 1,
        reason: 'Jag har inte högt blodtryck (men trodde att jag hade det)'
      },
      {
        id: 2,
        reason: 'Jag vill hålla koll själv/har egen mätare'
      },
      {
        id: 3,
        reason: 'Annat',
        isFreeText: true
      }
    ]
  },
  {
    id: 2,
    category: 'Jag var bara nyfiken på mätaren eller appen',
    subReasons: [
      {
        id: 0,
        reason: 'Jag har inte högt blodtryck (hypertoni)'
      },
      {
        id: 1,
        reason: 'Jag förstod inte att det var en vårdtjänst'
      },
      {
        id: 2,
        reason: 'Jag ville bara ha en blodtrycksmätare'
      },
      {
        id: 3,
        reason: 'Annat',
        isFreeText: true
      }
    ]
  },
  {
    id: 3,
    category: 'Det var för krångligt eller funkade inte',
    subReasons: [
      {
        id: 0,
        reason: 'Det var för tekniskt svårt för mig'
      },
      {
        id: 1,
        reason: 'Jag hade för mycket strul med mätaren'
      },
      {
        id: 2,
        reason: 'Det var för långt för mig att ta mig till en provtagningsenhet'
      },
      {
        id: 3,
        reason: 'Jag upplever behandlingsprogrammet som för krävande'
      },
      {
        id: 4,
        reason: 'Annat',
        isFreeText: true
      }
    ]
  },
  {
    id: 4,
    category: 'Har inte möjlighet av personliga skäl',
    subReasons: [
      {
        id: 0,
        reason: 'Jag känner mig för stressad i livet just nu'
      },
      {
        id: 1,
        reason: 'Jag har inte tid just nu'
      },
      {
        id: 2,
        reason: 'Jag orkar inte ta tag i mitt höga blodtryck just nu'
      },
      {
        id: 3,
        reason: 'Jag upplever behandlingsprogrammet som för krävande'
      },
      {
        id: 4,
        reason: 'Annat',
        isFreeText: true
      }
    ]
  },
  {
    id: 5,
    category: 'Annat',
    isFreeText: true,
    subReasons: []
  }
];

export const initialState = {
  authToken: null,
  isLoading: false,
  isEditingAddress: false,
  memberData: {},
  memberDataError: null,
  memberDataUpdated: false,
  selectedCategory: null,
  freeTextReason: '',
  isSubmittingForm: false,
  formSubmitted: false,
  submitError: null,
  question: 'Varför vill du lämna tjänsten?',
  transactionType: 'return',
  selectedSubReason: null,
  answers
};

export const actionTypes = {
  setMemberUuid: 'setMemberUuid',
  setIsEditingAddress: 'setIsEditingAddress',
  setLoadingState: 'setLoadingState',
  setMemberData: 'setMemberData',
  setMemberDataError: 'setMemberDataError',
  updateMemberDataProperty: 'updateMemberDataProperty',
  selectAnswerCategory: 'selectAnswerCategory',
  setFreeTextReason: 'setFreeTextReason',
  submitForm: 'submitForm',
  submitFormSuccess: 'submitFormSuccess',
  submitFormError: 'submitFormError',
  setTransactionType: 'setTransactionType',
  selectSubReason: 'selectSubReason'
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setMemberUuid:
      return { ...state, memberUuid: action.memberUuid };
    case actionTypes.setIsEditingAddress:
      return { ...state, isEditingAddress: true };
    case actionTypes.setLoadingState:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.setMemberData:
      const member = action.response;

      return {
        ...state,
        memberData: member,
        isLoading: false,
        isEditingAddress: !member.city || !member.streetaddress || !member.postalCode
      };
    case actionTypes.updateMemberDataProperty:
      return {
        ...state,
        memberDataUpdated: true,
        memberData: {
          ...state.memberData,
          [action.property]: action.value
        }
      };
    case actionTypes.selectAnswerCategory:
      return {
        ...state,
        selectedCategory: state.answers.find((answer) => answer.id === window.parseInt(action.categoryId, 10)),
        selectedSubReason: null
      };
    case actionTypes.setFreeTextReason:
      return { ...state, freeTextReason: action.freeTextReason };
    case actionTypes.submitForm:
      return { ...state, isSubmittingForm: true, submitError: null };
    case actionTypes.submitFormSuccess:
      return { ...state, formSubmitted: true, isSubmittingForm: false };
    case actionTypes.submitFormError:
      return { ...state, submitError: action.error, isSubmittingForm: false };
    case actionTypes.setMemberDataError:
      return { ...state, memberDataError: action.error, isLoading: false };
    case actionTypes.setTransactionType:
      return { ...state, transactionType: action.transactionType };
    case actionTypes.selectSubReason:
      return {
        ...state,
        selectedSubReason: state.selectedCategory.subReasons.find(
          (reason) => reason.id === window.parseInt(action.subReasonsId, 10)
        )
      };
    default:
      throw new TypeError(`Unrecognized action type: ${action.type}`);
  }
};
